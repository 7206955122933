<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <div class="searchPart flex-between-y-center">
                <div class="menu_title">茶叶加工技艺监测数据</div>
                <div class="flex-y-center acTPart">
                    <sn-search
                        class="sn-search clearfix"
                        :snSearch.sync="snSearch"
                        @handleChange="handleChange"
                        style="padding-left: 0; width: auto"
                    ></sn-search>
                </div>
            </div>
            <!-- 基础数据 -->
            <div class="basePart flex-y-center">
                <div class="label">基础数据</div>
                <el-select
                    v-model="formdata.dataDic.SJID"
                    placeholder="请选择"
                    size="mini"
                >
                    <el-option
                        v-for="(item, index) in baseDataList"
                        :key="index"
                        :label="item.MC"
                        :value="item.ID"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="cyjgjcLabel">茶叶加工进程</div>
            <div class="timePart flex-between-y-center">
                <div class="line"></div>
                <div
                    v-for="(item, index) in listAry"
                    :key="index"
                    class="timeItem pointer"
                    @click="getProgressId(item, index)"
                >
                    <div
                        class="circle"
                        :class="getBgClass(item, index)"
                        :style="{
                            background: index == activeNum ? '#ffcc66' : '',
                        }"
                    ></div>
                    <div
                        class="name"
                        :class="getColorClass(item, index)"
                        :style="{
                            color: index == activeNum ? '#ffcc66' : '',
                        }"
                    >
                        {{ item.NAME }}
                    </div>
                </div>
            </div>
            <div class="actPart">
                <el-form
                    ref="formdata"
                    :inline="true"
                    :model="formdata.dataDic"
                    :rules="rules"
                    class="demo-form-inline form_row"
                    size="small"
                    label-width="200px"
                >
                    <el-form-item
                        label="说明"
                        prop="QKSM"
                        :style="{ width: '80%' }"
                    >
                        <el-input
                            :disabled="!isEdit"
                            v-model="formdata.dataDic.QKSM"
                            placeholder="请填写名称"
                            type="textarea"
                            :rows="6"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item
                        label="时间"
                        prop="CJSJ"
                        :style="{ width: '80%' }"
                    >
                        <el-date-picker
                            :disabled="!isEdit"
                            v-model="formdata.dataDic.CJSJ"
                            value-format="yyyy-MM"
                            type="date"
                            placeholder="选择日期"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item
                        label="附件"
                        prop="ZP"
                        :style="{ width: '80%' }"
                    >
                        <basic-upload
                            v-if="isEdit"
                            savePath="monitor/cyjgjy_monitor"
                            ref="uploadFile"
                            :itemId="formdata.itemCode"
                            :metaData.sync="formdata.metaJson"
                            basicType="imgAndVideo"
                            @handleChange="handleChange"
                        ></basic-upload>
                    </el-form-item>
                </el-form>
            </div>
            <div class="imgPart flex flex-wrap scrollbar" v-viewer>
                <el-image
                    v-for="(item, index) in imgList"
                    :key="index"
                    :src="item"
                    fit="cover"
                    class="imgItem"
                >
                    <div slot="error" style="width: 100%; height: 100%">
                        <SnNoData width="120px" typeNum="1"></SnNoData>
                    </div>
                </el-image>
            </div>
            <div class="videoPart flex flex-wrap scrollbar" v-viewer>
                <video
                    controls
                    v-for="(item, index) in videoList"
                    :key="index"
                    :src="item"
                    class="videoItem"
                ></video>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import basicUpload from "@comp/basic_upload";
export default {
    components: {
        basicUpload,
    },
    data() {
        const validatPic = (rule, value, cb) => {
            if (!this.formdata.fileids) {
                cb(new Error("没有传图片"));
            } else {
                cb();
            }
        };
        return {
            title: "",
            keyword: "",
            snSearch: {
                inputData: [
                    // {
                    //     label: "关键字",
                    //     placeholder: "请输入关键字",
                    //     value: "keyword",
                    //     operateType: "search",
                    //     isShow: false,
                    // },
                ],
                selectData: [],
                searchBtn: {
                    buttonData: [
                        {
                            isShow: true,
                            btnType: "button",
                            operateType: "saveDataAdmin",
                            name: "保存",
                            round: true,
                            backColor: "#28cdd9",
                            color: "#fff",
                        },
                        // {
                        //     isShow: true,
                        //     btnType: "button",
                        //     operateType: "saveNosubmit",
                        //     editType: "1",
                        //     name: "保存暂不提交",
                        //     round: true,
                        //     backColor: "#28cdd9",
                        //     color: "#fff",
                        // },
                        // {
                        //     isShow: true,
                        //     btnType: "button",
                        //     operateType: "saveSubmit",
                        //     editType: "1",
                        //     name: "保存并提交",
                        //     round: true,
                        //     backColor: "#6f32ff",
                        //     color: "#fff",
                        // },
                        // {
                        //     isShow: false,
                        //     btnType: "button",
                        //     operateType: "savePass",
                        //     editType: "2",
                        //     name: "审核通过",
                        //     round: true,
                        //     backColor: "#28cdd9",
                        //     color: "#fff",
                        // },
                        // {
                        //     isShow: false,
                        //     btnType: "button",
                        //     operateType: "saveNoPass",
                        //     editType: "2",
                        //     name: "审核不通过",
                        //     round: true,
                        //     backColor: "#6f32ff",
                        //     color: "#fff",
                        // },
                        {
                            isShow: true,
                            btnType: "button",
                            operateType: "returnList",
                            editType: "0,1,2",
                            name: "返回",
                            round: true,
                            backColor: "#ff9066",
                            color: "#fff",
                        },
                    ],
                },
            },
            imgList: [],
            videoList: [],
            listAry: [],
            searchData: [
                { type: "SJID" },
                { type: "JC" },
                { type: "DEPARTMENTID" },
            ],
            imgState: false,
            enumsList: [], //枚举列表
            baseDataId: "",
            formdata: {
                dataDic: {
                    ID: "",
                    glycbtid: this.heritageId,
                    JC: "", //进程id
                    SJID: "", //基础数据id
                    QKSM: null,
                    CJSJ: null,
                    SHZT: null,
                    PIC: [],
                },
                fileids: "",
                itemCode: "500102",
                metaJson: [],
            },
            rules: {
                QKSM: [
                    {
                        required: true,
                        message: "请填写名称",
                        trigger: ["blur"],
                    },
                ],
                CJSJ: [
                    {
                        required: true,
                        message: "请选择",
                        trigger: ["blur"],
                    },
                ],
                ZP: [{ validator: validatPic, required: true }],
            },
            activeNum: null,
            isEdit: true,
            baseDataList: [],
            progressDataId: "", //进程的数据id
        };
    },
    watch: {
        fileidsValue() {
            this.$refs.formdata.validateField(["ZP"]);
        },
        // 修改数据的时候 获取 附件id
        formdata: {
            immediate: true,
            deep: true,
            handler: function () {
                if (this.formdata.metaJson.length) {
                    this.formdata.fileids = this.formdata.metaJson
                        .map((item) => item.jcsjid)
                        .join(",");
                }
            },
        },
    },
    computed: {
        ...mapGetters(["heritageId", "system"]),
        fileidsValue() {
            return this.formdata.fileids;
        },
    },
    created() {},
    mounted() {
        this.formdata.dataDic.SJID = JSON.parse(this.$route.query.val).ID;
        this.getBasicData();
        this.getTeaProgressList();
    },
    methods: {
        ...mapActions([
            "getMonitorDataList",
            "updateShztOfMonitorData",
            "saveMonitorData",
            "getMonitorDataDetails",
            "GetCyjgjyJcData",
        ]),
        // 获取基础数据列表
        async getBasicData() {
            let result = await this.getMonitorDataList({
                userid: localStorage.userId,
                itemId: "500101",
                // authority: false,
                // keyword,
                // strwhere: strwhere,
                // sort: this.sort,
                // sortorder: this.order,
                // start: this.start,
                // end: this.end,
                pageIndex: 1,
                pageSize: 99999,
            });
            this.baseDataList = result.ResultValue.gridData;
        },
        // 茶叶加工进程 枚举数据
        async getTeaProgressList() {
            let result = await this.GetCyjgjyJcData({
                sjid: this.formdata.dataDic.SJID,
            });
            this.listAry = result.RESULTVALUE;
            this.listAry.map((item, index) => {
                if (item.ISDQJC) {
                    this.activeNum = index;
                    this.progressDataId = item.ID;
                    this.formdata.dataDic.ID = item.ID;
                    this.formdata.dataDic.QKSM = "";
                    this.formdata.dataDic.CJSJ = "";
                    this.formdata.fileids = "";
                    this.formdata.metaJson = [];
                    this.formdata.dataDic.JC = item.JC;
                    this.getDetail();
                }
            });
            this.getDetail();
        },
        // 控制按钮显示 这里不调用 min里面的方法
        contralBtn() {
            // 这里只有 保存按钮
        },
        // 获取详情
        async getDetail() {
            let result = await this.getMonitorDataDetails({
                itemId: this.formdata.itemCode,
                id: this.progressDataId ? this.progressDataId : "", //基础数据的id
            });
            if (result && result[0] && result[0].Data && result[0].Data[0]) {
                const temp = result[0];
                const { Data, metaJson, PIC, ...restData } = temp;
                Object.assign(this.formdata.dataDic, Data[0]);
                this.formdata.metaJson = metaJson;
                this.formdata.PIC = PIC;
                this.restData = restData;
            } else {
                this.formdata.dataDic.QKSM = "";
                this.formdata.dataDic.CJSJ = "";
                this.formdata.fileids = "";
                this.formdata.metaJson = [];
            }
        },
        // 各种操作
        handleChange(type, value) {
            switch (type) {
                case "search": // 修改搜索条件(搜索及按钮)
                    this[value[1]] = value[0];
                    this.searchData.forEach((item) => {
                        if (item.type === value[1]) {
                            item.value = value[0];
                        }
                    });
                    this.getDetail(); // 是否需要点击查询按钮才进行搜索
                    break;
                case "searchBtn": // 点击查询按钮
                    this.getDetail();
                    break;
                case "returnList": // 点击返回按钮
                    this.$router.push({
                        name: "monitoringTable",
                        params: {
                            ItemID: this.formdata.itemCode,
                            MC: "null",
                        },
                    });
                    break;
                case "saveNosubmit": // 保存暂不提交未做验证
                    this.saveNosubmit();
                case "saveDataAdmin": // 保存管理员 只修改数据 不改变状态
                    this.saveDataAdmin();
                    break;
                case "saveSubmit": // 保存并提交
                    this.saveSubmit();
                    break;
                case "savePass": // 审核通过
                    this.savePass();
                    break;
                case "saveNoPass": // 审核不通过
                    this.saveNoPass();
                    break;
            }
        },
        // 获取进程背景色Class
        getBgClass(item, index) {
            let className = "";
            if (item.ID) {
                className = "beforeItemBg";
            } else {
                className = "afterItemBg";
            }
            return className;
        },
        // 获取进程  颜色 Class
        getColorClass(item, index) {
            let className = "";
            if (item.ID) {
                className = "beforeItemColor";
            } else {
                className = "afterItemColor";
            }
            return className;
        },
        // 超级管理员 保存数据 不修改数据状态
        async saveDataAdmin() {
            let basicValidPromise = this.$refs.formdata.validate();
            let metaValidPromise =
                this.$refs.uploadFile && this.$refs.uploadFile.validate();
            Promise.all([basicValidPromise, metaValidPromise])
                .then(([basicValid, metaValid]) => {
                    this.saveData();
                })
                .catch((e) => {
                    this.common.showMsg("请检查是否填写完整！", "warning");
                });
        },
        // 保存暂不提交未做验证
        saveNosubmit() {
            let basicValidPromise = this.$refs.formdata.validate();
            let metaValidPromise =
                this.$refs.uploadFile && this.$refs.uploadFile.validate();

            Promise.all([basicValidPromise, metaValidPromise])
                .then(([basicValid, metaValid]) => {
                    this.formdata.dataDic.SHZT = window.SHZT.NOT_SUBMITED;
                    this.saveData();
                })
                .catch((e) => {
                    this.common.showMsg("请检查是否填写完整！", "warning");
                });
        },
        // 保存并提交
        saveSubmit() {
            let basicValidPromise = this.$refs.formdata.validate();
            let metaValidPromise =
                this.$refs.uploadFile && this.$refs.uploadFile.validate();
            Promise.all([basicValidPromise, metaValidPromise])
                .then(([basicValid, metaValid]) => {
                    this.formdata.dataDic.SHZT = window.SHZT.SUBMITED;
                    this.saveForm();
                })
                .catch(() => {
                    this.common.showMsg("请检查是否填写完整！", "warning");
                });
        },
        // 审核通过
        savePass() {
            this.saveSHZT(2);
        },
        // 审核不通过
        saveNoPass() {
            this.saveSHZT(3);
        },
        saveSHZT(zt) {
            this.$confirm("审核提交后不能更改，你确认要提交吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    if (Number(zt) === Number(window.SHZT.AUDIT_NOT_PASSED)) {
                        this.$prompt("请输入未审核通过原因", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                        })
                            .then(({ value }) => {
                                if (value == null || value.trim().length == 0) {
                                    this.common.showMsg(
                                        "输入无效,请重新审核",
                                        "info"
                                    );
                                } else {
                                    this.updateSjzt(zt, value);
                                }
                            })
                            .catch(() => {
                                this.common.showMsg("已取消", "info");
                            });
                    } else {
                        this.updateSjzt(zt);
                    }
                })
                .catch(() => {
                    this.common.showMsg("已取消审核", "info");
                });
        },
        async updateSjzt(zt, reason) {
            let res = await this.updateShztOfMonitorData({
                itemid: this.formdata.itemCode,
                sjid: this.formdata.dataDic.ID,
                shzt: zt,
                shrid: this.userId,
                shsj: this.common.getTimeYmdhms(),
                shbtgsm: reason ? reason : "",
                rwid: "",
            });
            if (res.IsSuccess) {
                this.getDetail();
                this.common.showMsg("审核成功", "success");
            }
        },
        saveForm() {
            this.$confirm("数据提交后不能更改，你确认要提交吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.formdata.dataDic.SHZT = window.SHZT.SUBMITED;
                    this.saveData();
                })
                .catch(() => {
                    this.common.showMsg("已取消提交", "info");
                });
        },
        async saveData() {
            console.log(
                "%c 🥘 this.formdata: ",
                "font-size:20px;background-color: #E41A6A;color:#fff;",
                this.formdata
            );
            // this.dealCJRAndRWID(); // 加载采集人信息
            let res = await this.saveMonitorData(this.formdata);
            if (res.IsSuccess) {
                this.getTeaProgressList();
                this.common.showMsg("保存成功", "success");
            }
        },
        dealCJRAndRWID() {
            if (this.formdata.dataDic.CJR) return;
            this.formdata.dataDic.CJR = localStorage.getItem("userRealName");
            this.formdata.dataDic.CJRID = localStorage.getItem("userId");
            this.formdata.dataDic.RWID = this.$route.query.RWID || "";
        },
        // 获取进程id
        getProgressId(item, index) {
            this.activeNum = index;
            this.formdata.dataDic.JC = item.JC;
            this.formdata.dataDic.ID = item.ID ? item.ID : "";
            this.progressDataId = item.ID ? item.ID : "";
            this.getDetail();
        },
    },
};
</script>

<style scoped lang="scss">
.searchPart {
    height: 60px;
    position: relative;
    border-bottom: 1px solid #e4e4e4;
    .acTPart {
        position: relative;
        top: 10px;
    }
}
.basePart {
    padding: 10px 0;
    border-bottom: 1px solid #e4e4e4;
    .label {
        margin-right: 10px;
    }
}
.cyjgjcLabel {
    margin-top: 10px;
}
.timePart {
    margin-top: 20px;
    padding: 0 100px;
    position: relative;
    .line {
        width: 100%;
        height: 1px;
        background: #e4e4e4;
        position: absolute;
        left: 0;
        top: 5px;
        z-index: 1;
    }
    .timeItem {
        position: relative;
        z-index: 2;
        .beforeItemBg {
            background: #006666;
        }
        .nowItemBg {
            background: #ffcc66;
        }
        .afterItemBg {
            background: #333333;
        }
        .beforeItemColor {
            color: #006666;
        }
        .nowItemColor {
            color: #ffcc66;
        }
        .afterItemColor {
            color: #333333;
        }
        .circle {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin: 0 auto;
            margin-bottom: 20px;
        }
        .name {
            margin-top: 20px;
            font-size: 16px;
        }
    }
}

.imgPart {
    width: 976px;
    margin-top: 10px;
    margin-left: 196px;
    height: 260px;
    .imgItem {
        width: 224px;
        height: 230px;
        margin-right: 23px;
        margin-bottom: 23px;
        &:nth-child(4n) {
            margin-right: 0;
        }
    }
}

.videoPart {
    width: 976px;
    margin-left: 196px;
    height: 260px;
    .videoItem {
        width: 224px;
        height: 230px;
        margin-right: 23px;
        margin-bottom: 23px;
        &:nth-child(4n) {
            margin-right: 0;
        }
    }
}
</style>
